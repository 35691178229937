<template>
    <div v-if="type !== 'glossar'">
        <section id="sidebar">
            <div class="sidebar-icons">

                <div class="filter-bar" v-if="type === 'questions'">
                    <div class="filter-category">
                        <feather-icon class="icon-question clickable" v-b-tooltip.hover title='Alle Fragen'
                            icon="FolderIcon" size="15"
                            :style="{ color: getQuestionFilterArray.includes('all') ? this.activeColor : '#818181' }"
                            @click="updateQuestionFilter('all')"></feather-icon>
                            <div>{{ getAllQuestionsCount }}</div>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon-question clickable" v-b-tooltip.hover title='Unbeantwortete Fragen'
                            icon="CircleIcon" size="15"
                            :style="{ color: getQuestionFilterArray.includes('unanswered') ? this.activeColor : '#818181' }"
                            @click="updateQuestionFilter('unanswered')"></feather-icon>
                        <div>{{ getCountQuestionUnanswered }}</div>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon-question clickable" v-b-tooltip.hover title='Beantwortete Fragen'
                            icon="CheckCircleIcon" size="15"
                            :style="{ color: getQuestionFilterArray.includes('answered') ? this.activeColor : '#818181' }"
                            @click="updateQuestionFilter('answered')"></feather-icon>
                        <div>{{ getCountQuestionAnswered }}</div>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon-question clickable" v-b-tooltip.hover title='Markierte Fragen'
                            icon="BookmarkIcon" size="15"
                            :style="{ color: getQuestionFilterArray.includes('starred') ? this.activeColor : '#818181' }"
                            @click="updateQuestionFilter('starred')"></feather-icon>
                        <div>{{ getCountQuestionStarred }}</div>
                    </div>
                </div>

                <div class="filter-bar" v-if="type === 'cards'">
                    <div class="filter-category">
                        <feather-icon class="icon clickable" v-b-tooltip.hover title='Alphabetisch' icon="TypeIcon"
                            :style="{ color: getCategoryFilterArray.includes('abc') ? this.activeColor : '#818181' }"
                            @click="updateCategoryFilter('abc')"></feather-icon>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon clickable" v-b-tooltip.hover title='Relevanz' icon="AlignLeftIcon"
                            :style="{ color: getCategoryFilterArray.includes('relevance') ? this.activeColor : '#818181' }"
                            @click="updateCategoryFilter('relevance')"></feather-icon>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon clickable" v-b-tooltip.hover title='Favoriten' icon="StarIcon"
                            :style="{ color: getCategoryFilterArray.includes('starred') ? this.activeColor : '#818181' }"
                            @click=" updateCategoryFilter('starred')"></feather-icon>
                    </div>
                </div>

                <div class="filter-bar" v-if="type === 'profile'">
                    <div class="filter-category">
                        <feather-icon class="icon" :class="getShowProfileInOverview ? 'clickable' : null" v-b-tooltip.hover
                            :title="getShowProfileInOverview ? 'Alphabetisch' : ''" icon="TypeIcon"
                            :style="{ color: getCategoryFilterArray.includes('abc') && getShowProfileInOverview ? this.activeColor : '#818181' }"
                            @click="getShowProfileInOverview ? updateCategoryFilter('abc') : null"></feather-icon>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon" :class="getShowProfileInOverview ? 'clickable' : null" v-b-tooltip.hover
                            :title="getShowProfileInOverview ? 'Relevanz' : ''" icon="AlignLeftIcon"
                            :style="{ color: getCategoryFilterArray.includes('relevance') && getShowProfileInOverview ? this.activeColor : '#818181' }"
                            @click="getShowProfileInOverview ? updateCategoryFilter('relevance') : null"></feather-icon>
                    </div>
                    <div class="filter-category">
                        <feather-icon class="icon" :class="getShowProfileInOverview ? 'clickable' : null" v-b-tooltip.hover
                            :title="getShowProfileInOverview ? 'Favoriten' : ''" icon="StarIcon"
                            :style="{ color: getCategoryFilterArray.includes('starred') && getShowProfileInOverview ? this.activeColor : '#818181' }"
                            @click="getShowProfileInOverview ? updateCategoryFilter('starred') : null"></feather-icon>
                    </div>
                </div>

            </div>
        </section>
    </div>
    <div v-else>
        <section id="sidebar-glossar">
            <div class="sidebar-icons">
                <div class="filter-bar" v-if="type === 'glossar'">
                    <div class="filter-category">
                        <h4>Glossar filtern nach</h4>
                    </div>
                    <b-list-group>
                        <div v-for="category in kb_categories" :key="category.id">
                            <b-list-group-item v-if="!functionDisabled" class="clickable list-item"
                                :style="{ fontWeight: getGlossarFilterArray.includes(category.id) ? 'bold' : 'normal', display: 'flex' }"
                                @click="updateGlossarFilter(category.id)">
                                <b-icon class="icon clickable" icon="square-fill"
                                    :style="{ 'color': category.color }"></b-icon>
                                <div>{{ category.name }} </div>
                            </b-list-group-item>
                            <b-list-group-item v-if="functionDisabled" class="list-item"
                                :style="{ fontWeight: getGlossarFilterArray.includes(category.id) ? 'bold' : 'normal', display: 'flex' }">
                                <b-icon class="icon" icon="square-fill" :style="{ 'color': category.color }"></b-icon>
                                <div>{{ category.name }} </div>
                            </b-list-group-item>
                        </div>
                    </b-list-group>
                </div>
            </div>

        </section>
    </div>
</template>
<script>

import Button from './Button.vue'
import FeatherIcon from '../components/FeatherIcon.vue'

export default {
    name: 'nav-sidebar',
    props: {
        buttonLabel: String,
        buttonFunction: String,
        link: String,
        icons: Array,
        type: String,
        functionDisabled: Boolean,
    },
    components: {
        Button,
        FeatherIcon
    },
    computed: {
        getCountQuestionStarred() {
            return this.$store.getters['survey/getCountQuestionStarred']
        },

        getCountQuestionAnswered() {
            return this.$store.getters['survey/getCountQuestionAnswered']
        },
        getCountQuestionUnanswered() {
            return this.$store.getters['survey/getCountQuestionUnanswered']
        },

        getQuestionFilterArray() {
            return this.$store.getters['survey/getQuestionFilterArray']
        },
        getCategoryFilterArray() {
            return this.$store.getters['survey/getCategoryFilterArray']
        },
        getGlossarFilterArray() {
            return this.$store.getters['survey/getGlossarFilterArray']
        },
        getAllQuestionsCount() {
            return this.$store.getters['survey/getAllQuestionsCount']
        },
        kb_categories() {
            return this.$store.getters['survey/getAllTermCategories']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },

    },
    methods: {

        updateQuestionFilter(value) {
            let payload = {}
            payload = { value: value }
            this.$store.commit("survey/UPDATE_QUESTION_FILTER", payload)
        },
        updateCategoryFilter(value) {
            let payload = {}
            payload = { value: value }
            this.$store.commit("survey/UPDATE_CATEGORY_FILTER", payload)
        },
        updateGlossarFilter(value) {
            let payload = {}
            payload = { value: value }
            this.$store.commit("survey/UPDATE_GLOSSAR_FILTER", payload)
            if (this.$route.params.filter !== 'all')
                this.$router.push('/knowledge-base')
        },


    },
    data() {
        return {
            wertesystem: "Finden Sie alleine oder im Team heraus, welche Werte  Ihre Forschung bestimmen und Ihrem Forschungsprojekt eine Form geben sollen.",
            profil: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes  bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
            karten: "Informieren Sie sich zu relevan-ten ELSI-Kategorien und lassen Sie sich von den Fallbeispielen und dem Reflexionsprozess inspirieren.",
            reflexion: "Reflektieren Sie ihr eigenes Forschungprojekt anhand einer indivialisierbaren ELSI-Checkliste und leisten Sie den Transfer von den ELSI-Inhalten zum eigenen Forschungsvorhaben.",
            isAllQuestions: true,
            isUnansweredQuestions: false,
            isAnsweredQuestions: false,
            isStarredQuestions: false,
            isAllCategorys: false,
            sortABC_Category: false,
            sortRelevanceCategory: false,
            isStarredCategory: false,
            sortFilterCategory: [],
            filterArray: [],
            isSelected: false,
            activeColor: '#4BB3D4',
            inactiveColor: 'black',
        }
    }
}

</script>

<style lang="scss" scoped>
section#sidebar {
    background-color: white;
    height: fit-content;
    border-radius: 6px;
    width: 100px;
    min-width: 100px;
    padding: 32px 0 32px 0;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
    }

    .sidebar-icons {
        display: flex;
        justify-content: center;

        .filter-bar {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .filter-category {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 20px;
            }

            .icon {
                scale: 1;
                display: flex;

            }

            .icon-question {
                scale: 1.25;
                display: flex;

            }
        }
    }
}

section#sidebar-glossar {
    background-color: white;
    height: fit-content;
    border-radius: 6px;
    width: 310px;
    min-width: 100px;
    padding: 32px 0 32px 0;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
    }

    .sidebar-icons {
        display: flex;
        justify-content: center;

        .filter-bar {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .filter-category {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 20px;
            }

            .list-item {
                display: flex;
                gap: 20px;

            }

            .icon {
                scale: 1;
                display: flex;

            }

            .icon-question {
                scale: 1.25;
                display: flex;

            }
        }
    }
}


.icon {
    display: flex;
    align-self: center;
}

.list-group-item {
    border: none;
    width: fit-content;
}


[click] {
    cursor: pointer;
}
</style>