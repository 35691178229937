<template>
    <div class="headerCard">
        <div class="heading">
            <div class="headline">
                <h4>
                    <slot name="title">Title</slot>
                </h4>
            </div>
            <div v-if="icons.length > 0">
                <b-list-group horizontal>
                    <b-list-group-item class="icon-item noborder clickable" v-for="(item, index) in icons" :key="index" :id="item"
                    :style="{backgroundColor: '#ECF0F3'}" @click="click($event, item)">
                        <div v-if="item === 'projectCreation'">
                            <feather-icon class="clickable" font-scale="1.5" icon="Edit3Icon"></feather-icon>
                        </div>
                        <div v-if="item === 'trash-fill'">
                            <feather-icon class="clickable aufgabe" font-scale="1.5" icon="Trash2Icon"></feather-icon>
                        </div>
                        <div v-if="item === 'download'">
                            <feather-icon class="clickable" font-scale="1.5" icon="DownloadIcon"></feather-icon>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>

            <b-tooltip target="projectCreation" title="Projekt bearbeiten"></b-tooltip>
            <b-tooltip target="trash-fill" title="Projekt löschen"></b-tooltip>
            <b-tooltip target="download" title="Projekt herunterladen" placement="bottom"></b-tooltip>

        </div>
        <slot name="description" class="text"></slot>
        <div style="width:100%" :class="linkTo === '/survey' ? 'profilInfo' : null">
            <b-card v-if="info === 'true'" :class="linkTo === '/survey' ? 'info' : 'info survey'" :style="cssVars">
                <div style="display: flex; flex-direction: row;">
                    <div style="margin: 0 16px 0 16px; align-self: center; color: #818181">
                        <feather-icon icon="ArrowRightCircleIcon"></feather-icon></div>
                    <div>
                        <slot name="info" />
                    </div>
                </div>
            </b-card>
            <div v-if="linkTo === '/survey'" style="display: flex; justify-content: center;">
                <div v-if="link && linkTo === '/profile'" style="display: flex; align-self: center">
                    <Button btnStyle="btn-primary" :label="buttonLabel" icon="Edit3Icon" :chevron="false" :link="link"
                        :linkTo="linkTo"></Button>
                </div>
                <div v-if="link && linkTo === '/survey'" style="display: flex; align-self: center">
                    <Button btnStyle="btn-primary" :label="buttonLabel" icon="Edit3Icon" :chevron="false" :link="link"
                        :linkTo="linkTo"></Button>
                </div>
            </div>
        </div>

        <b-modal v-model="showModalCreation" title="Projektdaten bearbeiten" @ok="createOK" size="xl"
            cancel-title="Abbrechen" ok-title="Übernehmen" footer-class="modal-footer-buttons">
            <ProjectCreationModal ref="projectCreationModal" showImport="false" />
        </b-modal>

        <b-modal size="lg" v-model="showDownload" title="Projekt herunterladen" :hide-footer=true>
            <Download></Download>
        </b-modal>

        <b-tooltip target="nav-header-question-circle" title="Tutorial anzeigen"></b-tooltip>
        <b-tooltip target="nav-header-download" title="Projekt herunterladen"></b-tooltip>
        <b-tooltip target="nav-header-trash-fill" title="Projekt löschen"></b-tooltip>


        <b-modal v-model="showModalDelete" title="Projekt löschen" ok-title="Löschen" cancel-title="Abbrechen"
            footer-class="modal-footer-buttons" @ok="handleOk">
            <DeleteProjectModal />
        </b-modal>

    </div>
</template>

<script>

import Button from "../components/Button.vue";
import ProjectCreationModal from "../components/Modal_Project_Creation.vue";
import DeleteProjectModal from "../components/Modal_Delete_Project";
import FeatherIcon from '../components/FeatherIcon.vue';
import html2pdf from 'html2pdf.js';
import Download from '../components/Modal_Project_Download.vue';

export default {
    name: "header-card",
    props: {
        title: String,
        description: String,
        info: String,
        info_color: {
            type: String,
            default: 'red'
        },
        icons: Array,
        link: Boolean,
        linkTo: String,
        buttonLabel: String,
    },
    components: {
        Button,
        ProjectCreationModal,
        DeleteProjectModal,
        FeatherIcon,
        Download,
        html2pdf

    },
    computed: {
        cssVars() {
            return {
                '--border-color': this.infoColor,
            }
        }
    },
    methods:
    {
        createOK() {
            this.$refs.projectCreationModal.clickedOK()
        },
        downloadOK() {
            switch (this.downloadOption) {
                case 'json':
                    this.saveFile()
                    break;
                case 'pdf':
                    this.createPDF()
                    break;
            }
        },
        getOption(option) {
            this.downloadOption = option
        },
        backClicked() {
            this.$router.back()
        },
        click(evt, item) {
            switch (item) {
                case 'projectCreation':
                    this.showModalCreation = !this.showModalCreation
                    break;
                case 'question-circle':
                    //this.$emit('showTutorial-clicked')
                    this.showTutorial = !this.showTutorial
                    break;
                case 'download':
                    this.showDownload = !this.showDownload
                    //this.saveFile()
                    break;
                case 'trash-fill':
                    this.showModalDelete = !this.showModalDelete
                    break;
            }
        },
        createPDF() {
            let opt = {
                margin: 30,
                filename: 'ELSI-SAT Ergebnis.pdf',
                image: { type: 'jpeg', quality: 1 },
                // Scales larger than 2 tend to result in empty pages.
                // https://github.com/eKoopmans/html2pdf.js/issues/19#issuecomment-315583260
                html2canvas: { scale: 1.8 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all' }
            };

            const element = document.getElementById('pdf-export-content');

            html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
                let totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    pdf.text(
                        'Seite ' + i + ' von ' + totalPages,
                        pdf.internal.pageSize.getWidth() - 116,
                        pdf.internal.pageSize.getHeight() - 15
                    );
                }
            }).save();

        },
        saveFile: function () {

            const data = JSON.stringify(this.$store.state.survey)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = "ELSI-SAT-Export.json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        deleteProject() {
            this.showModalDelete = false
            this.deleteFinal = false
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.$store.commit('survey/DELETE_PROJECT')
            this.$emit('deleteProject')
            this.showModalDelete = false
            this.$router.push('/');
            //this.deleteFinal = !this.deleteFinal
        },
        test(item) {
            switch (item) {
                case 'projectCreation':
                    this.showModalCreation = !this.showModalCreation
                    break;
                case 'question-circle':
                    //this.$emit('showTutorial-clicked')
                    this.showTutorial = !this.showTutorial
                    break;
                case 'download':
                    this.showDownload = !this.showDownload
                    //this.saveFile()
                    break;
                case 'trash-fill':
                    this.showModalDelete = !this.showModalDelete
                    break;
            }
        },
        changeProjectMeta(e) {
            this.showModal = !this.showModal
        },

    },
    data() {
        return {
            showModalCreation: false,
            showModalDelete: false,
            deleteFinal: false,
            infoColor: this.info_color,
            showTutorial: false,
            showDownload: false,
            downloadOption: '',
            showModal: false,
        }
    }
}

</script>

<style lang="scss" scoped>
.profilInfo {
    display: flex;
    gap: 90px;
}

.aufgabe {
    color: $elsi-color-aufgabe;
}

.headerCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 20px;
    width: 100%;

    background: #FFFFFF;
    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .headline {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height, or 114% */

        }
    }

    .info {
        box-sizing: border-box;
        background-color: $elsi-color-bar-bg;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 85%;
        border:none;
        border-left: solid;
        border-left-width: 10px;
        border-left-color: var(--border-color);
        line-height: 25px;

        .icon {
            margin-top: 3px;
            color: #4BB3D4;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    .survey {
        width: 100%;
    }

    .icon-item {
        background-color: #ECF0F3;
        margin-left: 1rem;
        border-radius: 3px;

        &:hover {
            background-color: white;
        }

    }

}
</style>